<template>
  <v-card
    elevation="5"
    class="bkop-light pa-6"
  >
    <h1
      class="headline"
    >
      <span>
        {{ $t('meta.footer.copyright.title') }}
      </span>
      <svg
        class="d-inline align-center ml-1 degraded-opacity"

        alt="Creative Commons: BY NC"
        style="height:24px;width:auto;transform: translateY(4px)"
        width="240"
        height="64"
        version="1.0"
        viewBox="0 0 240 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g style="fill:currentColor">
          <path d="M61.66,19.69A30.87,30.87,0,0,0,54.8,9.37,31.23,31.23,0,0,0,31.94,0,30.3,30.3,0,0,0,9.48,9.31a32.32,32.32,0,0,0-7,10.49,31.61,31.61,0,0,0,7,34.74,32.15,32.15,0,0,0,10.4,7A31.14,31.14,0,0,0,31.94,64a31.84,31.84,0,0,0,12.29-2.46,33.09,33.09,0,0,0,10.63-7.08,29.5,29.5,0,0,0,6.82-10.15A32.45,32.45,0,0,0,64,32,32.56,32.56,0,0,0,61.66,19.69ZM50.74,50.35A27.74,27.74,0,0,1,42,56.12a26,26,0,0,1-19.92,0A27.12,27.12,0,0,1,7.8,41.91,25.35,25.35,0,0,1,7.8,22a27.37,27.37,0,0,1,5.8-8.6A24.81,24.81,0,0,1,32.06,5.77a25.32,25.32,0,0,1,18.57,7.72A25.54,25.54,0,0,1,56.28,22,26.16,26.16,0,0,1,58.23,32,24.6,24.6,0,0,1,50.74,50.35Z" />
          <path d="M23.83,37.66a3.69,3.69,0,0,1-3.21-1.55A7,7,0,0,1,19.54,32q0-5.66,4.29-5.66a3.77,3.77,0,0,1,1.85.58,4.39,4.39,0,0,1,1.69,2l4.29-2.23q-2.58-4.64-8.52-4.63a9,9,0,0,0-6.71,2.69A9.87,9.87,0,0,0,13.77,32a9.91,9.91,0,0,0,2.63,7.32A9.36,9.36,0,0,0,23.31,42a9.43,9.43,0,0,0,8.4-5l-3.94-2A4,4,0,0,1,23.83,37.66Z" />
          <path d="M42.28,37.66a3.67,3.67,0,0,1-3.2-1.55A7,7,0,0,1,38,32q0-5.66,4.28-5.66a3.92,3.92,0,0,1,1.92.58,4.38,4.38,0,0,1,1.68,2l4.23-2.23q-2.51-4.64-8.45-4.63a9.06,9.06,0,0,0-6.72,2.69A9.87,9.87,0,0,0,32.28,32a9.94,9.94,0,0,0,2.61,7.32A9.32,9.32,0,0,0,41.83,42a9.17,9.17,0,0,0,4.85-1.35,9.71,9.71,0,0,0,3.54-3.68l-4-2A4,4,0,0,1,42.28,37.66Z" />
        </g>
        <g
          transform="translate(80)"
          style="fill:currentColor"
        >
          <path d="M54.68,9.26A30.92,30.92,0,0,0,31.94,0,30.64,30.64,0,0,0,9.43,9.26,31.34,31.34,0,0,0,0,32,30.79,30.79,0,0,0,9.43,54.57,30.94,30.94,0,0,0,31.94,64a31.6,31.6,0,0,0,22.91-9.54Q64,45.49,64,32A30.94,30.94,0,0,0,54.68,9.26ZM13.54,50.4A25.14,25.14,0,0,1,5.77,32,25.73,25.73,0,0,1,13.6,13.43,24.94,24.94,0,0,1,32.06,5.77a25.2,25.2,0,0,1,18.51,7.66A25.29,25.29,0,0,1,58.23,32a24.37,24.37,0,0,1-7.55,18.34,25.67,25.67,0,0,1-18.63,7.83A25.27,25.27,0,0,1,13.54,50.4Z" />
          <path d="M38.57,22H25.43a2,2,0,0,0-2.06,2.06V37.14H27V52.68H37V37.14h3.66V24.06A2,2,0,0,0,40,22.6,2,2,0,0,0,38.57,22Z" />
          <path d="M32,20.29q4.45,0,4.46-4.46T32,11.32q-4.45,0-4.46,4.51T32,20.29Z" />
        </g>
        <g
          transform="translate(160)"
          style="fill:currentColor"
        >
          <path d="M54.68,9.26A30.92,30.92,0,0,0,31.94,0,30.64,30.64,0,0,0,9.43,9.26,31.34,31.34,0,0,0,0,32,30.74,30.74,0,0,0,9.43,54.52,30.76,30.76,0,0,0,31.94,64a31.6,31.6,0,0,0,22.91-9.54Q64,45.49,64,32A30.82,30.82,0,0,0,54.68,9.26ZM32.05,58.12A25.51,25.51,0,0,1,13.54,50.4,25.14,25.14,0,0,1,5.77,32,25.24,25.24,0,0,1,7.2,23.37l16.34,7.31,5.09,2.23,3.77,1.72,3.08,1.31a2.43,2.43,0,0,1,.86,2,2.67,2.67,0,0,1-1.17,2.45,5.13,5.13,0,0,1-2.83.75,10.22,10.22,0,0,1-7.14-2.92l-4.4,4.46a14.87,14.87,0,0,0,9.71,3.83v4.91h3.72V46.46A10.58,10.58,0,0,0,40,44.26,8.27,8.27,0,0,0,43,39.31l12,5.37a23.12,23.12,0,0,1-4.4,5.61A25.43,25.43,0,0,1,32.05,58.12ZM50.57,13.43A25,25,0,0,1,58.23,32a29.78,29.78,0,0,1-.8,6.86L41,31.54l-5.14-2.28L33.09,28,29,26.17a1.38,1.38,0,0,1-.29-.8,2,2,0,0,1,1.18-1.94,6,6,0,0,1,2.65-.57,9.5,9.5,0,0,1,5.49,1.88l4.17-4.28a14.13,14.13,0,0,0-8-3V12.57H30.52v4.92a10,10,0,0,0-5.2,1.68,7.46,7.46,0,0,0-3,4L10.06,17.72a29.84,29.84,0,0,1,3.54-4.29A24.8,24.8,0,0,1,32.06,5.71,25,25,0,0,1,50.57,13.43Z" />
        </g>
      </svg>
    </h1>

    <p class="subtitle-1 mt-3">
      {{ $t('meta.footer.copyright.content') }}
    </p>

    <v-btn
      v-haptic
      text
      outlined
      href="https://creativecommons.org/licenses/by-nc/4.0/"
      target="_blank"
      rel="noopener"
    >
      {{ $t('meta.details') }}
      <v-icon
        right
        small
      >
        mdi-open-in-new
      </v-icon>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'License'
}
</script>

<style scoped>

</style>
