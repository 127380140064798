import { render, staticRenderFns } from "./SeabornSideAdornment.vue?vue&type=template&id=08c376d0&scoped=true&"
import script from "./SeabornSideAdornment.vue?vue&type=script&lang=js&"
export * from "./SeabornSideAdornment.vue?vue&type=script&lang=js&"
import style0 from "./SeabornSideAdornment.vue?vue&type=style&index=0&id=08c376d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c376d0",
  null
  
)

export default component.exports