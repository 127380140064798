<template>
  <div class="d-flex flex-row align-start">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FactTable'
}
</script>

<style scoped>

</style>
