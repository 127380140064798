var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticClass:"transparent elevation-0 full-width pa-md-2 pa-lg-4 pa-xl-4",attrs:{"alt-labels":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',{staticClass:"bkop-light elevation-4 py-2 pl-5 pr-8 d-flex flex-row position-relative align-center justify-center",staticStyle:{"border-radius":"4px"}},[_c('v-img',{staticClass:"stepper-header--background stepper-header--background__animated",attrs:{"src":_vm.headerImage,"position":""}},[_c('v-overlay',{style:({
          background: _vm.dark
            ? 'linear-gradient(150deg, rgba(0, 0, 0, .95), rgba(0, 0, 0, 0))'
            : 'linear-gradient(150deg, rgba(255, 255, 255, .95), rgba(255, 255, 255, 0))',
        }),attrs:{"absolute":"","opacity":"0"}})],1),_c('BackButton',{staticClass:"my-2",attrs:{"name":_vm.$t('item.choose.name'),"active":_vm.step > 1},on:{"back":function($event){_vm.step = 1}}}),_c('v-spacer'),_c('v-slide-x-transition',[(_vm.step === 2 && _vm.isSelectedItem && _vm.relatedItems.length)?_c('div',{staticClass:"d-flex flex-row scrollbar-hidden",staticStyle:{"overflow-y":"visible","overflow-x":"scroll","z-index":"4","padding-bottom":"1px"}},_vm._l((_vm.relatedItems),function(item){return _c('span',{directives:[{name:"haptic",rawName:"v-haptic"}],key:item.itemId,staticClass:"mr-1 cursor-pointer",on:{"click":function($event){return _vm.storeItemSelection(item.itemId)}}},[_c('v-badge',{staticClass:"d-flex",attrs:{"bordered":"","bottom":"","overlap":"","offset-x":16,"offset-y":20,"color":"green darken-1","icon":"mdi-check","value":item.itemId === _vm.selectedItem.itemId}},[_c('span',{style:({
                filter:
                  item.itemId === _vm.selectedItem.itemId
                    ? _vm.dark
                      ? 'drop-shadow(0 0 3px rgba(0, 0, 0, .6))'
                      : 'drop-shadow(0 0 3px rgba(255, 255, 255, .6))'
                    : 'none',
              })},[_c('Item',{attrs:{"item":item,"ratio":0.6}})],1)])],1)}),0):_vm._e()])],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pa-0",staticStyle:{"border-radius":"4px"},attrs:{"step":1}},[_c('v-card',{staticClass:"bkop-light elevation-4 mt-2 mt-4 pa-4"},[_c('ItemSelector',{on:{"select":_vm.storeItemSelection}})],1)],1),_c('v-stepper-content',{staticClass:"pa-0 mt-2",attrs:{"step":2}},[_c('v-card',{staticClass:"bkop-light elevation-4 mt-2"},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-row',{staticClass:"px-4 px-sm-4 px-md-6 px-lg-6 px-xl-8 pt-0 pb-4 flex-column flex-sm-row align-center align-sm-end",attrs:{"justify":"center"}},[_c('div',{staticClass:"d-flex align-center w-full align-self-start align-self-sm-end"},[_c('Item',{attrs:{"item":_vm.selectedItem,"ratio":0.7,"disable-tooltip":"","disable-link":""}}),_c('h1',{staticClass:"title pl-2 pt-1 no-wrap--text"},[_vm._v(" "+_vm._s(_vm.$t("result.title", { item: _vm.selectedItemName }))+" ")])],1),_c('v-spacer'),_c('div',{staticClass:"flex text-center text-sm-right"},[_c('MatrixCategoryToggle'),_c('DataSourceToggle')],1)],1)],1),_c('DataTable',{staticClass:"px-3 px-sm-4 px-md-6 px-lg-6 px-xl-8 pt-0 pb-6",attrs:{"items":_vm.itemStagesStats,"type":"item","trends":_vm.trends}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }