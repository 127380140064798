var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{staticClass:"mb-2",attrs:{"hover":!_vm.large,"readonly":_vm.large},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},_vm._l((_vm.zones),function(zone){return _c('v-expansion-panel',{key:zone.zoneId,staticClass:"bkop-light stage-card--background",style:({ 'background-image': zone.background ? ("url(" + (_vm.cdnDeliver(zone.background)) + ") !important") : null })},[_c('v-expansion-panel-header',{directives:[{name:"haptic",rawName:"v-haptic"}],staticClass:"overflow-hidden bkop-medium",class:{ 'stage-card--header': !!zone.background, 'stage-card--transparent': _vm.large },attrs:{"hide-actions":_vm.large}},[_c('v-row',{attrs:{"align":"center"}},[(zone.isActivity && !_vm.small && zone.timeValid !== undefined)?_c('span',{staticClass:"text--darken-1 font-weight-bold ml-2 mr-1",class:{
            'red--text': zone.timeValid === 1,
            'green--text': zone.timeValid === 0,
            'grey--text': zone.timeValid === -1
          }},[_vm._v(" "+_vm._s(_vm.$t("zone.status." + zone.timeValid))+" ")]):_vm._e(),(zone.isPermanentOpen)?_c('span',{staticClass:"text--darken-1 font-weight-bold orange--text ml-2 mr-1"},[_vm._v(" "+_vm._s(_vm.$t("zone.status.permanentOpen"))+" ")]):_vm._e(),_c('span',{staticClass:"subtitle-1 pl-2",class:{
            'text--darken-1 font-weight-bold': zone.isActivity && _vm.small,
            'red--text': zone.isActivity && _vm.small && zone.timeValid === 1,
            'green--text': zone.isActivity && _vm.small && zone.timeValid === 0,
            'grey--text': zone.isActivity && _vm.small && zone.timeValid === -1
          }},[_vm._v(" "+_vm._s(_vm.strings.translate(zone, "zoneName"))+" ")]),_c('v-spacer'),(zone.isActivity)?_c('v-chip',{staticClass:"mr-8",attrs:{"small":"","disabled":""}},[_vm._v(" "+_vm._s(zone.activityStartDate)+" ")]):_vm._e()],1)],1),_c('v-expansion-panel-content',{class:{ 'stage-card--content': !!zone.background, 'stage-card--large-content': _vm.large }},[(zone.isActivity)?_c('div',{staticClass:"caption mx-1 mt-3 mb-2"},[_vm._v(" "+_vm._s(_vm.genActivityTime(zone))+" ")]):_vm._e(),(zone.type !== 'GACHABOX')?_c('div',{staticClass:"pt-2"},_vm._l((zone.stages),function(stage){return _c('StageCard',{directives:[{name:"haptic",rawName:"v-haptic"}],key:stage.stageId,attrs:{"stage":stage},nativeOn:{"click":function($event){return _vm.$emit('select', {zoneId: zone.zoneId, stageId: stage.stageId})}}})}),1):_c('div',{staticClass:"pt-2"},[_vm._l((zone.stages.filter(function (el) { return el.stageId.startsWith('randomMaterialRune'); })),function(stage){return _c('StageCard',{directives:[{name:"haptic",rawName:"v-haptic"}],key:stage.stageId,attrs:{"stage":stage},nativeOn:{"click":function($event){return _vm.$emit('select', {zoneId: zone.zoneId, stageId: stage.stageId})}}})}),_c('v-divider',{staticClass:"my-2"}),_vm._l((zone.stages.filter(function (el) { return !el.stageId.startsWith('randomMaterialRune'); })),function(stage){return _c('StageCard',{directives:[{name:"haptic",rawName:"v-haptic"}],key:stage.stageId,attrs:{"stage":stage},nativeOn:{"click":function($event){return _vm.$emit('select', {zoneId: zone.zoneId, stageId: stage.stageId})}}})})],2)])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }