<template>
  <v-subheader class="px-0">
    <slot />
    <v-divider class="ml-4" />
  </v-subheader>
</template>

<script>
export default {
  name: 'Subheader'
}
</script>

<style scoped>

</style>
